import { applyStylesIf, cx } from 'utils/cx'

import fukkuStyles from 'fukku/styles/classes.module.scss'
import styles from '../../ModalHeader.module.scss'

export function HeadersTitles({
	title,
	subtitle,
	showTitle,
	hasSheetTitle,
	hasCenteredTitle,
	className,
}: {
	readonly title: string
	readonly showTitle: boolean
	readonly hasSheetTitle: boolean
	readonly hasCenteredTitle: boolean
	readonly subtitle?: string | React.ReactNode
	readonly className?: string
}) {
	return showTitle ? (
		<div
			className={cx(
				className,
				styles.titles,
				hasCenteredTitle && styles.centered
			)}
		>
			<h1
				className={cx(
					styles.title,
					applyStylesIf(hasSheetTitle, styles.sheetTitle)
				)}
				data-testid='modal.title'
			>
				{title}
			</h1>
			{!!subtitle && (
				<h2 className={styles.subtitle} data-testid='modal.subtitle'>
					{subtitle}
				</h2>
			)}
		</div>
	) : (
		<div className={cx(fukkuStyles.srOnly, styles.title)}>{title}</div>
	)
}
