import { applyStylesIf, cx } from 'utils/cx'

import { CloseModal } from '../../CloseModal/CloseModal'

import fukkuStyles from 'fukku/styles/classes.module.scss'
import styles from '../ModalHeader.module.scss'

export function CloseOnlyHeader({
	close,
	title,
	floatClose,
	preventClose,
	className,
}: {
	readonly title: string
	readonly preventClose: boolean
	readonly floatClose: boolean
	readonly close: () => void
	readonly className: string
}) {
	return (
		<header
			className={cx(
				className,
				styles.header,
				applyStylesIf(!floatClose, styles.closeOnly),
				applyStylesIf(floatClose, styles.noHeight)
			)}
		>
			<div className={styles.positionAnchor}>
				<span className={fukkuStyles.srOnly}>{title}</span>
				<CloseModal
					preventClose={preventClose}
					close={close}
					float={floatClose}
				/>
			</div>
		</header>
	)
}
