import { IconCloseMedium } from 'icons/components/IconCloseMedium'
import { applyStylesIf, cx } from 'utils/cx'

import fukkuStyles from 'fukku/styles/classes.module.scss'
import styles from './CloseModal.module.scss'

export const CloseModal = ({
	float,
	margin,
	preventClose,
	close,
	asBar,
}: {
	readonly float?: boolean
	readonly margin?: boolean
	readonly preventClose?: boolean
	readonly asBar?: boolean
	readonly close: () => void
}) => {
	if (preventClose) {
		return null
	}

	if (asBar) {
		return (
			<button
				type='button'
				onClick={close}
				data-testid='modal.close.button'
				className={styles.bar}
			>
				<span className={fukkuStyles.srOnly} lang='en'>
					Close
				</span>
			</button>
		)
	}

	return (
		<button
			onClick={close}
			data-testid='modal.close.button'
			className={cx(
				styles.close,
				applyStylesIf(!!float, styles.float),
				applyStylesIf(!!margin, styles.margin)
			)}
			type='button'
		>
			<IconCloseMedium lang='en' alt='Close' />
		</button>
	)
}
